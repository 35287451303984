import angular from "angular";
import get from "lodash/get";
import find from "lodash/find";
import filter from "lodash/filter";

import { checkAuthorizationForUiComponent } from "@skryv/core-ng1/core/store/actions/authorizations";
import { prepareDownload } from "@skryv/core-ng1/core/store/actions/documents";
import {
  fetchDossierDetails,
  updateDossier,
} from "@skryv/core-ng1/core/store/actions/dossiers";
import { fetchUsersForRole } from "@skryv/core-ng1/core/store/actions/users";
import { fetchDossierManagers } from "@skryv/bundle/customizations/actions/users";
import { selectDossierManagers } from "../../../selectors/users";
import { selectDossierDetails } from "@skryv/core-ng1/core/store/selectors/dossiers";

import template from "./CpDossier.html";
import "./CpDossier.scss";

const namespace = "meemoo/components/dossier/CpDossier";

/*
 * We want to show information per process type, but the information is given us per dossier.
 * So we need to group all tasks, documents, milestones,... by their process, using their keys as identifier.
 * For each type of process, we also try to fetch the relevant attachments for easy access on the dossier overview
 */

angular.module(namespace, []).component("meemooCpDossier", {
  template,
  bindings: {
    apiDossier: "<",
    dosdef: "<",
    procdefs: "<",
    processes: "<",
    authorizations: "<",
  },
  controller: function (
    $filter,
    $ngRedux,
    $state,
    dialogService,
    notifications,
    userService
  ) {
    "ngInject";
    const $ctrl = this;
    const disconnect = $ngRedux.connect(mapStateToThis, {
      fetchDossierDetails,
      fetchDossierManagers,
      fetchUsersForRole,
      prepareDownload,
      updateDossier,
      checkAuthorizationForUiComponent,
    })(this);
    this.$onDestroy = disconnect;

    function mapStateToThis(state) {
      const dossierDetails = selectDossierDetails(state, $ctrl.dossierId);

      let users;
      let dossierManager;

      if (dossierDetails) {
        users = selectDossierManagers(state, $ctrl.dossierId);
        const dossierManagerSub = dossierDetails.dossier.dossierManager;
        dossierManager = find(users, (user) => user.sub == dossierManagerSub);
        $ctrl.initAllProcessInfoTypes(dossierDetails);
      }

      return {
        users,
        dossierManager,
        dossierDetails,
      };
    }

    this.$onInit = () => {
      this.dossierId = get(this.apiDossier, "id");
      this.dossierExternalId = get(this.apiDossier, ["externalId"]);
      this.initDossier();
      this.checkAuthorizationForUiComponent(
        userService.getCurrentUserSub(),
        "EDIT_DOSSIER_METADATA"
      ).then(({ api }) => {
        this.isAuthorizedForEditingMetadata = get(
          api,
          "response.data.authorized"
        );
      });
      this.fetchDossierManagers(this.dossierId);
    };

    this.$onChanges = () => {
      if (this.dossierId) {
        this.initDossier();
      }
    };

    this.initDossier = () => {
      this.loadingInitialDetails = true;

      return this.fetchDossierDetails(this.dossierId).then((dossierDetails) => {
        this.loadingInitialDetails = false;
        this.initAllProcessInfoTypes(dossierDetails.api.response.data);
        this.initializeAttachments();
      });
    };

    this.initAllProcessInfoTypes = (dossierDetails) => {
      this.initIV(dossierDetails);
      this.initSA(dossierDetails);
      this.initSO(dossierDetails);
      this.initTF(dossierDetails);
    };

    this.initializeAttachments = () => {
      if (this.iv_docs && !this.iv_attachments) {
        // old process and new process
        this.iv_attachments = getAttachments(
          this.iv_docs,
          "intentieverklaring_viaa",
          "intentieverklaring_viaa.getekende_intentieverklaring",
          "Intentieverklaring",
          "attachment_getekende_intentieverklaring"
        ).concat(
          getAttachments(
            this.iv_docs,
            "intentieverklaring_viaa_v2",
            "intentieverklaring_viaa_v2.getekende_intentieverklaring",
            "Intentieverklaring",
            "attachment_getekende_intentieverklaring"
          )
        );
      }

      let addDefaultTypeAndVersion = (attachmentsOfOldSOProcess) => {
        for (let i = 0; i < attachmentsOfOldSOProcess.length; i++) {
          attachmentsOfOldSOProcess[i].type = "Standaard";
          attachmentsOfOldSOProcess[i].version = "juli 2016";
        }
        return attachmentsOfOldSOProcess;
      };

      if (this.so_docs) {
        // old process (only Hoofddocument) and new process (addenda do not have type and version)
        this.so_attachments = addDefaultTypeAndVersion(
          getAttachments(
            this.so_docs,
            "getekende_samenwerkingsovereenkomst",
            "getekende_samenwerkingsovereenkomst.getekende_samenwerkingsovereenkomst",
            "Hoofddocument",
            "attachment_getekende_samenwerkingsovereenkomst"
          )
        )
          .concat(
            getAttachments(
              this.so_docs,
              "getekende_samenwerkingsovereenkomst_documenten",
              "getekende_samenwerkingsovereenkomst_documenten.te_ondertekenen_documenten.subfields.getekende_versie",
              "Hoofddocument",
              "attachment_te_ondertekenen_documenten.getekende_versie",
              "selectie_te_ondertekenen_documenten",
              "selectie_te_ondertekenen_documenten.te_ondertekenen_documenten.subfields.type.selectedOptionLabel.nl",
              "selectie_te_ondertekenen_documenten.te_ondertekenen_documenten.subfields.versie"
            )
          )
          .concat(
            getAttachments(
              this.so_docs,
              "getekende_samenwerkingsovereenkomst_documenten",
              "getekende_samenwerkingsovereenkomst_documenten.te_ondertekenen_documenten.subfields.getekende_topstukkenovereenkomst",
              "Topstukkenovereenkomst",
              "attachment_te_ondertekenen_documenten.getekende_topstukkenovereenkomst"
            )
          );

        const addenda = getAttachmentsFromList(
          this.so_docs,
          "getekende_samenwerkingsovereenkomst_documenten",
          "getekende_samenwerkingsovereenkomst_documenten.te_ondertekenen_documenten.subfields.addendum.elements",
          "getekende_versie",
          "Addendum ",
          "naam",
          "attachment_te_ondertekenen_documenten.addendum"
        );

        const filteredAddenda = filter(addenda, (addendum) => {
          return addendum.name != "Addendum GDPR addendum";
        });

        this.so_attachments = this.so_attachments.concat(filteredAddenda);
      }

      if (this.sa_docs && !this.sa_attachments) {
        this.sa_attachments = getAttachments(
          this.sa_docs,
          "getekende_service_agreement",
          "getekende_service_agreement.getekende_service_agreement_1",
          "Service Agreement",
          "attachment_getekende_service_agreement_1"
        );
      }
    };

    this.initIV = (dossierDetails) => {
      this.iv_tasks = $filter("filter")(
        get(dossierDetails, "task"),
        (v, i, a) =>
          [
            "UserTask_0aonjqp",
            "UserTask_044cdat",
            "Task_1xasop6",
            "UserTask_18fv4lz",
            "UserTask_1gtbn0q",
          ].indexOf(v.taskDefinitionKey) > -1
      );

      // documents in the old process were dossier-scoped, so only one document of each type was available at all times - no need to sort by process
      // for the old process: only the document containing the attachment is taken out
      this.iv_docs = [
        $filter("filter")(
          get(dossierDetails, "document"),
          (v, i, a) =>
            ["intentieverklaring_viaa"].indexOf(v.definition.key) > -1
        ),
      ];
      // documents in the new process are process-scoped, they are fetched based on the processes that belong to the intentieverklaring
      this.iv_docs = this.iv_docs.concat(
        getDocsForProcessKey("Intentieverklaring_v2", dossierDetails)
      );
    };
    this.initSA = (dossierDetails) => {
      this.sa_tasks = $filter("filter")(
        get(dossierDetails, "task"),
        (v, i, a) =>
          ["Task_InvullenSA", "Task_ControleerSA", "Task_UploadenSA"].indexOf(
            v.taskDefinitionKey
          ) > -1
      );
      this.sa_docs = getDocsForProcessKey("service_agreement", dossierDetails);
    };
    this.initSO = (dossierDetails) => {
      this.so_tasks = $filter("filter")(
        get(dossierDetails, "task"),
        (v, i, a) =>
          [
            "Task_19akzwo",
            "samenwerkingsovereenkomst",
            "tekenen_en_uploaden_van_getekende_samenwerkingsovereenkomst",
            "so_ondertekenproces",
            "samenwerkingsovereenkomst",
            "tekenen_en_uploaden_van_getekende_samenwerkingsovereenkomst",
          ].indexOf(v.taskDefinitionKey) > -1
      );

      // documents in the old process were dossier-scoped, so only one document of each type was available at all times - no need to sort by process
      // for the old process: only the document containing the attachment is taken out
      this.so_docs = [
        $filter("filter")(
          get(dossierDetails, "document"),
          (v, i, a) =>
            ["getekende_samenwerkingsovereenkomst"].indexOf(v.definition.key) >
            -1
        ),
      ];
      // documents in the new process are process-scoped, they are fetched based on the processes that belong to the samenwerkingsovereenkomst
      this.so_docs = this.so_docs
        .concat(getDocsForProcessKey("so_ondertekenproces", dossierDetails))
        .filter(Boolean);
    };
    this.initTF = (dossierDetails) => {
      this.tf_tasks = $filter("filter")(
        get(dossierDetails, "task"),
        (v, i, a) =>
          ["Activity_0qxwuey", "Activity_19y3pxv"].indexOf(
            v.taskDefinitionKey
          ) > -1
      );
      this.tf_docs = getDocsForProcessKey("dienstenformulier");
    };

    this.userDescription = (user) => {
      return userService.userDescription(user);
    };

    /*
     * Returns a list. For each process with the given key, there will be an element in the list
     * Each element is a list of documents that belong to the same process
     */
    let getDocsForProcessKey = (keyName, dossierDetails) => {
      let processes = $filter("filter")(this.processes, {
        processDefinitionKey: keyName,
      });
      if (processes) {
        let docidslist = processes.map((process) => process.docIds);
        if (docidslist && docidslist[0]) {
          return docidslist.map((docids) => {
            if (docids) {
              return docids.map((docid) =>
                find(get(dossierDetails, "document"), { id: docid })
              );
            }
          });
        }
      }
      return [];
    };
    /*
     * Get all relevant information concerning a specific attachment in a specific document
     *
     * @param docsets: documents grouped by process (see getDocsForProcessKey) to look through (e.g. all documents belonging to a certain process)
     * @param attachmentsDocumentKey: definition key of the type of document that contains the attachment
     *         --> the first document of this type that is encountered in the docset is used
     * @param attachmentPath: path of a field in the 'attachment document' (has key attachmentsDocumentKey) that holds the actual attachment
     *
     * @param name: name to display when displaying the attachment
     * @param attachmentFieldName: name of the field that is needed to download the attachment (see prepareDownload)
     *
     * @param metaInfoDocumentKey: definition key of the document where more information (e.g. version and type) about the attachment can be found
     *         --> this does not need to be the same document as the document where the attachment itself is located
     *         --> the first document of this type that is encountered in the docset is used
     * @param typePath: path of a field in the 'meta document' that contains information about the type of attachment
     * @param versionPath: path of a field in the 'meta document' that contains information about the version of the attachment
     */
    let getAttachments = (
      docsets,
      attachmentsDocumentKey,
      attachmentPath,
      name,
      attachementFieldName,
      metaInfoDocumentKey,
      typePath,
      versionPath
    ) => {
      let attachments = [];
      for (let i = 0; i < docsets.length; i++) {
        let doc = find(docsets[i], {
          definition: { key: attachmentsDocumentKey },
        });
        let attachment = get(doc, attachmentPath);

        if (doc && attachment) {
          let extendedAttachment = addMetaToAttachment(
            attachment,
            doc,
            name,
            attachementFieldName
          );
          extendedAttachment = addMetaDocInfoToAttachemnt(
            extendedAttachment,
            metaInfoDocumentKey,
            docsets[i],
            typePath,
            versionPath
          );
          attachments.push(extendedAttachment);
        }
      }

      return attachments;
    };
    /*
     * Get all relevant information concerning a specific list of attachments in a specific document
     *
     * @param docsets:documents grouped by process (see getDocsForProcessKey) to look through (e.g. all documents belonging to a certain process)
     * @param attachmentsDocumentKey: definition key of the type of document that contains the attachment
     *         --> the first document of this type that is encountered in the docset is used
     * @param attachmentListPath: path of a field in the 'attachment document' (has key attachmentsDocumentKey) that holds a list of attachments
     * @param attachmentElementPath: path of a field in the 'attachment list' (list indicated by attachmentListPath) that holds an actual attachment
     *
     * @param namePrefix: prefix used to build the name to display when displaying an attachment
     *         --> the name if build like this: namePrefix + ${result of namePath}
     * @parma namePath: path of a field in the 'attachment list' (list indicated by attachmentListPath) that holds a label for this attachment
     * @param attachmentFieldNamePrefix: prefix used to build the name of the field that is needed to download the attachment (see prepareDownload)
     *         --> the field is build like this: attachementFieldNamePrefix + '.' + ${index} + '.' + attachmentElementPath
     *
     * @param metaInfoDocumentKey: definition key of the document where more information (e.g. version and type) about the attachments can be found
     *         --> this does not need to be the same document as the document where the attachment itself is located
     *         --> the first document of this type that is encountered in the docset is used
     * @param typePath: path of a field in the 'meta document' that contains information about the type of attachment
     * @param versionPath: path of a field in the 'meta document' that contains information about the version of the attachment
     */
    let getAttachmentsFromList = (
      docsets,
      attachmentsDocumentKey,
      attachmentListPath,
      attachmentElementPath,
      namePrefix,
      namePath,
      attachmentFieldNamePrefix,
      metaInfoDocumentKey,
      typePath,
      versionPath
    ) => {
      let attachments = [];
      for (let i = 0; i < docsets.length; i++) {
        let doc = find(docsets[i], {
          definition: { key: attachmentsDocumentKey },
        });
        let attachmentList = get(doc, attachmentListPath);

        if (doc && attachmentList) {
          for (let j = 0; j < attachmentList.length; j++) {
            let attachment = get(attachmentList[j], attachmentElementPath);
            if (attachment) {
              let extendedAttachment = addMetaToAttachment(
                attachment,
                doc,
                namePrefix + get(attachmentList[j], namePath),
                attachmentFieldNamePrefix +
                  "." +
                  j +
                  "." +
                  attachmentElementPath
              );
              extendedAttachment = addMetaDocInfoToAttachemnt(
                extendedAttachment,
                metaInfoDocumentKey,
                docsets[i],
                typePath,
                versionPath
              );
              attachments.push(extendedAttachment);
            }
          }
        }
      }
      return attachments;
    };
    /*
     * Returns an object containing the given attachment and some meta data about it
     *
     * @param attachment: the attachment itself
     * @param doc: document this attachment was taken from (used to determine creation and update date of attachment)
     * @param name: name to display when displaying the attachment
     * @param attachmentFieldName: name of the field that is needed to download the attachment (see prepareDownload)
     */
    let addMetaToAttachment = (attachment, doc, name, attachementFieldName) => {
      const result = {
        attachment: attachment,
        name: name,
        url: "",
        created: doc.createdAt,
        updated: doc.updatedAt,
      };
      this.prepareDownload(get(attachment, "base"), {
        field: attachementFieldName,
      })
        .then(({ api }) => {
          result.url = get(api, "response.data.url");
        })
        .catch((err) => {
          console.debug("Error fetching url:", err);
        });

      return result;
    };
    /*
     * Fetch meta data about the attachment from a given document and add it to the given (extended) attachment
     *
     * @param extendedAttachment: attachment object that was returned by addMetaToAttachment
     * @param metaInfoDocumentKey: definition key of the document where more information (e.g. version and type) about the attachment can be found
     *    --> this does not need to be the same document as the document where the attachment itself is located
     *    --> the first document of this type that is encountered in the docset is used
     * @param docset: array of documents (they all belong to the same process) that contains the document with meta information
     * @param typePath: path of a field in the 'meta document' that contains information about the type of attachment
     * @param versionPath: path of a field in the 'meta document' that contains information about the version of the attachment
     */
    let addMetaDocInfoToAttachemnt = function (
      extendedAttachment,
      metaInfoDocumentKey,
      docset,
      typePath,
      versionPath
    ) {
      if (metaInfoDocumentKey) {
        let metaDoc = find(docset, {
          definition: { key: metaInfoDocumentKey },
        });
        let type = get(metaDoc, typePath);
        let version = get(metaDoc, versionPath);

        if (type) extendedAttachment.type = type;
        if (version) extendedAttachment.version = version;
      }
      return extendedAttachment;
    };

    this.getTasksForType = (docTypeName) => {
      switch (docTypeName) {
        case "iv":
          return this.iv_tasks;
        case "sa":
          return this.sa_tasks;
        case "so":
          return this.so_tasks;
        case "tf":
          return this.tf_tasks;
        default:
          return get(this.dossierDetails, "task");
      }
    };
    this.getDocumentsForType = (docTypeName) => {
      switch (docTypeName) {
        case "iv":
          return this.iv_docs;
        case "sa":
          return this.sa_docs;
        case "so":
          return this.so_docs;
        case "tf":
          return this.tf_docs;
        default:
          return get(this.dossierDetails, "document");
      }
    };
    this.getAttachmentsForType = (docTypeName) => {
      switch (docTypeName) {
        case "iv":
          return this.iv_attachments;
        case "sa":
          return this.sa_attachments;
        case "so":
          return this.so_attachments;
        case "tf":
          return this.tf_attachments;
        default:
          return [];
      }
    };

    /*
     * Comparator function that defines an order between SO attachments
     */
    this.getAttachmentsSortingForSO = (attachmentName1, attachmentName2) => {
      let giveValueToName = (name) => {
        switch (name) {
          case "Hoofddocument":
            return 1;
          default:
            return name;
        }
      };

      let v1 = giveValueToName(attachmentName1.value);
      let v2 = giveValueToName(attachmentName2.value);
      return v1 < v2 ? -1 : 1;
    };

    this.getTitleForType = (docTypeName) => {
      switch (docTypeName) {
        case "iv":
          return "Intentieverklaring";
        case "sa":
          return "Service Agreement";
        case "so":
          return "Samenwerkingsovereenkomst";
        case "tf":
          return "Dienstenformulier";
      }
    };

    /*
     * Returns the process definition of a process with the given key
     */
    let findProcDefForType = (docTypeProcdefKey) => {
      for (let i = 0; i < this.procdefs.length; i++) {
        if (get(this.procdefs[i], "key") === docTypeProcdefKey) {
          return this.procdefs[i];
        }
      }
      console.debug("DEBUG: no procdef found for the key " + docTypeProcdefKey);
    };

    this.startNewProcessForType = (procTypeName) => {
      let startProcessAction = (procdef) => {
        const title = `<span translate>Start process of type</span> "${procdef.name}"?`;
        const content = "<skr-dossier-add-process></skr-dossier-add-process>";
        const data = {
          procdef,
          dossier: this.apiDossier,
          workflow_type: "BPMN",
        }; // This assumes that only BPMN processes are used here
        dialogService.openDialog(title, content, data);
      };

      switch (procTypeName) {
        case "iv":
          return startProcessAction(
            findProcDefForType("Intentieverklaring_v2")
          );
        case "sa":
          return startProcessAction(findProcDefForType("service_agreement"));
        case "so":
          return startProcessAction(findProcDefForType("so_ondertekenproces"));
        case "tf":
          return startProcessAction(findProcDefForType("dienstenformulier"));
      }
    };

    this.canStartProcessForType = (procTypeName) => {
      return get(this.authorizations, "createProcess") || false;
    };

    this.redirectToDocument = (doc) => {
      $state.go("document", { documentId: doc.id });
    };

    this.toggleEditExternalId = () => {
      this.isEditingExternalId = !this.isEditingExternalId;
    };

    this.toggleEditDossierManager = () => {
      this.isEditingDossierManager = !this.isEditingDossierManager;
    };

    this.saveDossier = () => {
      // only the dossierManager and the externalId are editable from the overview
      const updatedDossier = Object.assign({}, this.apiDossier, {
        id: this.dossierId,
        dossierManager: get(this.dossierManager, "sub"),
        externalId: this.dossierExternalId,
      });
      this.updateDossier(updatedDossier)
        .then(() => {
          return this.fetchDossierDetails(this.dossierId);
        })
        .then(() => {
          notifications.info("Updated the dossier!");
          this.isEditingExternalId = false;
          this.isEditingDossierManager = false;
        })
        .catch(() => {
          notifications.error("Could not save the dossier");
        });
    };

    this.noITV = () => {
      if (
        !this.dossierDetails ||
        !this.dossierDetails.milestones ||
        !this.dossierDetails.process
      )
        return false;
      const itvProcesses = $filter("filter")(
        get(this.dossierDetails, "process"),
        function (em) {
          return (
            em.processDefinitionKey === "Intentieverklaring_v2" &&
            em.ended === undefined
          );
        }
      );
      if (itvProcesses.length > 0) return false;
      const milestones = get(this.dossierDetails, "milestones");
      const okMilestones = $filter("filter")(milestones, function (em) {
        return (
          em.key === "IntermediateThrowEvent_DS_ITV_akkoordITVenopstart" ||
          em.key === "IntermediateThrowEvent_DS_ITV_akkoordITVgeenopstart"
        );
      });
      if (okMilestones.length > 0) return false;
      const nokMilestones = $filter("filter")(milestones, function (em) {
        return (
          em.key ===
            "IntermediateThrowEvent_DS_ITV_geeninteressesamenwerkingVIAA" ||
          em.key ===
            "IntermediateThrowEvent_DS_ITV_misschienlatersamenwerkingVIAA" ||
          em.key ===
            "IntermediateThrowEvent_DS_ITV_geeninteressesamenwerkingVIAA"
        );
      });
      if (nokMilestones.length > 0) return true;
      return false;
    };

    this.noValidationSWODoc = () => {
      if (!this.dossierDetails || !this.dossierDetails.document) return false;
      const swoDocuments = $filter("orderBy")(
        $filter("filter")(
          get(this.dossierDetails, "document"),
          {
            definition: {
              key: "validatie_voorgestelde_samenwerkingsovereenkomst_documenten",
            },
          },
          true
        ),
        "updatedAt",
        true
      );
      if (!swoDocuments) return false;
      const SWODoc = swoDocuments[0];
      return (
        get(SWODoc, [
          "validatie_voorgestelde_samenwerkingsovereenkomst_documenten",
          "wij_verklaren_ons_akkoord_met_de_voorgestelde_samenwerkingsovereenkomst_documenten",
          "selectedOption",
        ]) == "neen"
      );
    };
  },
});
//.name;

export { template };
export default namespace;
