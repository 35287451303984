import angular from 'angular';
import get from 'lodash/get';

import { fetchDossierDetails } from '@skryv/core-ng1/core/store/actions/dossiers';
import { selectDossierDetails } from '@skryv/core-ng1/core/store/selectors/dossiers';

import template from './BriefingDossier.html';

const namespace = '@skryv/core-ng1/core/components/dossier/BriefingDossier';

angular
  .module(namespace, [])
  .component('meemooBriefingDossier', {
    template,
    bindings: {
      apiDossier: '<',
      dosdef: '<',
      procdefs: '<',
      authorizations: '<'
    },
    controller: function ($filter,$ngRedux,$state,dialogService) {
      'ngInject';
      const dateFilter = $filter('date');
      const timestampFormat = 'dd/MM/yyyy hh:mm';

      const $ctrl = this;
      const disconnect = $ngRedux.connect(mapStateToThis, { fetchDossierDetails })(this);
      this.$onDestroy = disconnect;

      this.$onInit = () => {
        this.dossierId = get(this.apiDossier, 'id');
        this.loadingInitialDetails = true;
        this.fetchDossierDetails(this.dossierId).then(() => this.loadingInitialDetails = false);
      };

      function mapStateToThis(state) {
        let newDossierDetails = selectDossierDetails(state,$ctrl.dossierId);

        return {
          dossierDetails: newDossierDetails,
          nbOfDocuments: get(newDossierDetails,'document',[]).length,
          nbOfActiveTasks: $filter('filter')(get(newDossierDetails,'task',[]),{ active: true }).length
        }
      }

      this.redirectToTasks = () => {
        $state.go('dossierTasks', { dossierId: this.dossierId }, {reload: true});
      };

      this.redirectToDocuments = () => {
        $state.go('dossierDocuments', { dossierId: this.dossierId }, { reload: true });
      };

      this.redirectToDocument = (doc) => {
        $state.go('document', { documentId: doc.id })
      };

      this.canStartProcess = () => {
        return get(this.authorizations, 'createProcess');
      };

      this.startNewProcess = () => {
        let startProcessAction = (procdef) => {
          const title = `<span translate>Start process of type</span> "${procdef.name}"?`;
          const content = '<skr-dossier-add-process></skr-dossier-add-process>';
          const data = { procdef, dossier: this.apiDossier, workflow_type: 'BPMN' }; // This assumes that only BPMN processes are used here
          dialogService.openDialog(title, content, data);
        };

        let procdefBriefing = this.procdefs[0];
        if(procdefBriefing.key === 'briefing_document') {
          return startProcessAction(procdefBriefing);
        }
        else {
          this.console.log('DEBUG: no procdef found for the key briefing');
        }
      };
    }
  });

export { template };
export default namespace;
