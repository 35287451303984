import angular from 'angular';
import get from 'lodash/get';

import { checkAuthorizationForUiComponent } from '@skryv/core-ng1/core/store/actions/authorizations';

import template from '@skryv/core-ng1/components/layout/Submenu/Submenu.html';

const namespace = 'meemoo/components/dossier/DossierSubmenu';

angular
  .module(namespace, [])
  .component('meemooDossierSubmenu', {
    bindings: {
      apiDossier: '<'
    },
    template,
    controller: function ($state, $ngRedux, userService) {
      'ngInject';

      const disconnect = $ngRedux.connect(null, { checkAuthorizationForUiComponent })(this);
      this.$onDestroy = disconnect;

      this.$onInit = function() {
        const dossierId = this.apiDossier.id;
        this.tabs = [{
          label: 'Overview',
          state: 'dossier',
          stateParams: { dossierId },
          stateOpts: { reload: true },
          isActive: () => $state.includes('dossier', { dossierId })
        }];
        this.checkAuthorizationForUiComponent(userService.getCurrentUserSub(), 'DOSSIER_LISTS')
          .then(({api}) => {
            const isAuthorized = get(api, 'response.data.authorized');
            if (isAuthorized) {
              this.tabs.push({
                label: 'Diagrams',
                state: 'dossierDiagram',
                stateParams: { dossierId },
                stateOpts: { reload: true },
                isActive: () => $state.includes('dossierDiagram', { dossierId })
              },{
                label: 'Documents',
                state: 'dossierDocuments',
                stateParams: { dossierId },
                stateOpts: { reload: true },
                isActive: () => $state.includes('dossierDocuments', { dossierId })
              }, {
                label: 'Tasks',
                state: 'dossierTasks',
                stateParams: { dossierId },
                stateOpts: { reload: true },
                isActive: () => $state.includes('dossierTasks', { dossierId })
              }, {
                label: 'Communications',
                state: 'dossierCommunications',
                stateParams: { dossierId },
                stateOpts: { reload: true },
                isActive: () => $state.includes('dossierCommunications', { dossierId })
              });
            }
          });
      }
    }
  });

export { template };
export default namespace;