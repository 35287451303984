import { Angular1Plugin } from '@skryv/core-ng1';

import components from './components';

export class MeemooCustomizations extends Angular1Plugin {
  constructor() {
    super('meemoo', [
      components
    ]);
  }
}

export default new MeemooCustomizations();
