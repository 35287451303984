import skryv from "@skryv/core-ng1";
import { navigation, theme, translation, search } from "@skryv/core-ng1";

import nl_BE from "./translations/nl_BE.po";

import meemooCustomizations from "./customizations";
import "./translations/template.html";

import * as dossierActions from "@skryv/core-ng1/core/store/actions/dossiers";
import * as documentActions from "@skryv/core-ng1/core/store/actions/documents";
import * as dossierSelectors from "@skryv/core-ng1/core/store/selectors/dossiers";
import * as documentSelectors from "@skryv/core-ng1/core/store/selectors/documents";

import logo from "./theme/assets/images/logo-negative.png";

import {
  KIBANA_SHORTCUT,
  SEARCH_SHORTCUT,
  PROFILE_SHORTCUT,
} from "@skryv/core-ng1/services/shortcuts/shortcutsConfiguration";

navigation
  .page("dashboard", {
    extend: true,
    views: {
      actions: "meemooDashboardActions",
    },
  })
  .page("dashboardDossiers", {
    extend: true,
    views: {
      actions: "meemooDashboardActions",
    },
  })
  .page("login", {
    extend: true,
    views: {
      content: "meemooLogin",
    },
  })
  .page("dossier", {
    extend: true,
    views: {
      content: "meemooDossier",
      subNavigation: "meemooDossierSubmenu",
    },
    resolve: {
      processes: processesForDossier(),
    },
  })
  .page("dossierTasks", {
    extend: true,
    views: {
      subNavigation: "meemooDossierSubmenu",
    },
  })
  .page("dossierDocuments", {
    extend: true,
    views: {
      subNavigation: "meemooDossierSubmenu",
    },
  })
  .page("dossierDiagram", {
    extend: true,
    views: {
      subNavigation: "meemooDossierSubmenu",
    },
  })
  .page("dossierCommunications", {
    extend: true,
    views: {
      subNavigation: "meemooDossierSubmenu",
    },
  })
  .page("task", {
    extend: true,
    params: {
      sidebar: {
        value: "info",
      },
    },
  })
  .setShortcutsForGroup("global", [
    KIBANA_SHORTCUT,
    SEARCH_SHORTCUT,
    PROFILE_SHORTCUT,
  ]);

search.defaultForDashboardMyTasks({
  name: "Default view",
  searchTerm: "",
  columns: ["task.annotations", "task.name", "dossier.label", "task.due"],
  filters: [],
  sort: {
    criterion: "task.due",
    desc: false,
  },
});

theme.logo(logo, "meemoo");

translation.add("nl_BE", nl_BE).locale("nl_BE");

skryv
  .customize(meemooCustomizations)
  .customize(navigation)
  .customize(search)
  .customize(theme)
  .customize(translation)
  .initialize(document.getElementById("root"));

function processesForDossier() {
  return ($q, $ngRedux, apiDossier) => {
    "ngInject";
    if (!apiDossier) return null;
    const dosId = apiDossier.id;
    return $ngRedux
      .dispatch(dossierActions.fetchDossierProcesses(dosId))
      .then((state) => {
        let processes = dossierSelectors.selectProcessesForDossier(
          state,
          dosId
        );
        return documentsForProcesses($q, $ngRedux, processes);
      });
  };
}

function documentsForProcesses($q, $ngRedux, processes) {
  // Return a promise that only resolves after we've fetched all document ids for all processes.
  // It resolves with the list of processes, but each process is extended with a property 'docIds'
  // (which is the list of document ids for that process)
  return $q.all(
    processes.map((process) =>
      $ngRedux
        .dispatch(documentActions.fetchDocumentsByProcessId(process.id))
        .then((state) =>
          process.set(
            "docIds",
            documentSelectors.selectDocumentIdsByProcess(state, process.id)
          )
        )
    )
  );
}
