import angular from 'angular';

import template from './MeemooLogin.html';
import './MeemooLogin.scss';

const namespace = 'meemoo/components/login/MeemooLogin';

angular
  .module(namespace, [])
  .component('meemooLogin', {
    template,
    controller: function ($state, authentication, notifications) {
      'ngInject';
      
      this.login = function () {
        authentication.login(this.username, this.password)
          .then(() => {
            notifications.info('Signed in!')
            //$state.go('dashboard')
          })
          .catch(e => {
            notifications.error('Could not sign in with that username and password');
          });
      }
    }
  });

export { template };
export default namespace;
