import angular from 'angular';
import template from './MeemooDossierProcessInfo.html';
import './MeemooDossierProcessInfo.scss';

const namespace = 'meemoo/components/dossier/CpDossier/MeemooDossierProcessInfo';

angular
  .module(namespace, [])
  .component('meemooDossierProcessInfo', {
    template,
    bindings: {
      docs: '<',
      tasks: '<',
      attachments: '<',
      loading: '<',
      documentTypeName: '<',
      attachmentSorting: '<',
      canStartProcess: '&',
      startNewProcess: '&',
      redirectToDocument: '&',
      redirectToTask: '&'
    },
    controller: function ($filter, $window) {
      'ngInject';

      this.$onInit = () => {
        this.taskToShow = filterTasks(this.tasks); //show only most recent
        this.docToShow = filterDocuments(this.docs); //show only most recent
        this.attachmentsToShow = filterAttachments(this.attachments); //show only most recent of each type

        if(!this.attachmentSorting) {
          this.attachmentSorting = (attachmentName1,attachmentName2) => {
            return (attachmentName1.value < attachmentName2.value) ? -1 : 1;
          };
        }
      };

      this.$onChanges = () => {
        this.taskToShow = filterTasks(this.tasks);
        this.docToShow = filterDocuments(this.docs); //show only most recent
        this.attachmentsToShow = filterAttachments(this.attachments); //show only most recent of each type
      };

      this.openAttachment = (attachment) => {
        $window.open(attachment.url, '_blank');
      };

      let filterTasks = (tasks) => {
        let filteredTasks = $filter('filter')(tasks,{ 'active':true });
        if(filteredTasks && filteredTasks.length > 0) {
          return $filter('orderBy')(filteredTasks,'created', true)[0];
        }
        else {
          return null;
        }
      };

      let filterDocuments = (docs) => {
        if(docs && docs.length > 0) {
          return $filter('orderBy')(docs,'updatedAt', true)[0];
        }
        else {
          return null;
        }
      };

      let filterAttachments = (allAttachments) => {
        if(allAttachments && allAttachments.length > 0) {
          let orderedAttachments = $filter('orderBy')(allAttachments, 'created', true);
          let filteredAttachmentsNames = [];
          let filteredAttachments = [];

          for (let i = 0; i < orderedAttachments.length; i++) {
            let attachment = orderedAttachments[i];

            if (filteredAttachmentsNames.indexOf(attachment.name) < 0) {
              filteredAttachmentsNames.push(attachment.name);
              filteredAttachments.push(attachment);
            }
          }
          return filteredAttachments;
        }
        else {
          return [];
        }
      };
    }
  });
  //.name;

export default namespace;


