import angular from 'angular';
import template from './MeemooDossier.html';

const namespace = 'meemoo/components/dossier/MeemooDossier';

angular
  .module(namespace, [])
  .component('meemooDossier', {
    template,
    bindings: {
      apiDossier: '<',
      dosdef: '<',
      procdefs: '<',
      processes: '<',
      authorizations: '<'
    },
  })
  .name;

export default namespace;
