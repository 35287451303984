import uniq from 'lodash/uniq';
import flatMap from 'lodash/flatMap';
import { selectUsersByDossierAndRole } from '@skryv/core-ng1/core/store/selectors/users';
import { dossierManagerRoles } from '@skryv/bundle/customizations/constants/roleKeys';

export const selectDossierManagers = (state, dossierId) => {
  return uniq(
    flatMap(dossierManagerRoles, (role) => {
      return selectUsersByDossierAndRole(state, dossierId, role);
    })
  );
}